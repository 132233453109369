#my {
  zoom: 75%;
}

/* index.css or any global CSS file */
html, body {
  margin: 0;
  padding: 0;
}

#root, .vh-100 {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-grow: 1;
  padding: 0;
}

footer {
  margin-top: auto;
  width: 100%;
}


body {
  font-size: 100%;
  max-width: 100%;
  padding-bottom: 0%;
  padding-top: 0%;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem
}


h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3rem
}


h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem
}

p {
  font-family: 'Nunito', sans-serif;
  font-size: 1.0rem;
  color: #687393
}

img.logo {
  min-width: 100px;
}

button {
  text-transform: 'none';
}

.grey-outline {
  border: 0.2rem solid #EEEBEB;
  border-radius: 1rem;
}

.blue-outline {
  border: 0.2rem solid #1F8BF4;
  border-radius: 1rem;
}

.problem-section, .CTA-section {
  background-color: #1F8BF4;
  color: white;
}

.about-section {
  background-color: #EEEBEB;
  color: white;
}

.problem-tile, .about-tile {
  background-color: white;
  color: #687393;
  border: 0.2rem solid white;
  border-radius: 0.5rem;
}

.donate-tile {
  background-color: white;
  border: 0.2rem solid white;
  border-radius: 0.5rem;
}

.main-form-body {
  min-height: 500px;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sticky-panel {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Adjust this value to control the offset from the top */
    z-index: 1000; /* Ensure it appears above other content */
    background-color: white; /* Optional: to maintain background color */
    padding: 10px; /* Optional: adjust padding as needed */
}
