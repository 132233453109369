button.form-card-button, label.form-check {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    border: #EEEBEB;
    background-color: white;
    color: black;
    border-style: solid;
    border-radius: 1rem;
}

button.form-card-button:hover, button.form-card-button:focus, button.form-card-button:active {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    border: #1f8bf4;
    border-style: solid;
    border-radius: 1rem;
}

.img {
    max-width: 75%;
}