div.form-body, div.dashboard-body {
    min-height: 700px
}


div.dashboard-body, div.dashboard-header {
    background-color: #F8F9FA;
}

div.review-card, div.trait-tile {
    background-color: white;
    border: #F8F9FA;
    border-style: solid;
    border-radius: 0.4rem;
}

div.info-tile {
    background-color: white;
    border: #7E7E7E;
    border-style: solid;
    border-radius: 0.4rem;
}

div.filters-column, div.search-input, div.show-shoe-tile, div.profile-information-tile, div.ask-ai-tile {
    background-color: white;
    border: white;
    border-style: solid;
    border-radius: 0.4rem;
}

div.ai-insights-alert {
    background-color: #F9EBFA;
    color: #B26EB8;
    border: #B26EB8;
    border-style: solid;
    border-radius: 0.4rem;
}

div.ask-ai-tile {
    border: #B26EB8;
    border-style: solid;
    border-radius: 0.4rem;
}

div.pros-tile, div.cons-tile {
    background-color: white;
    border: #F8F9FA;
    border-style: solid;
    border-radius: 0.4rem;
}


p.ai-insights-text {
    color: #B26EB8;
}

div.no-nav-or-footer {
    background-color: #F8F9FA;
}