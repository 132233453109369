.gradient-background {
    background-image: linear-gradient(to right, #0066FF , #1F8BF4)
}

.heroHeading {
    color: white;
    font-size: 3.5rem;
}

.boldedText {
    font-weight: 500;
}

.value-card {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
}

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.how-it-started, .principles, .team{
    opacity: 0;
    transform: translateY(20px); /* Start slightly off position */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.how-it-started.fade-in, .principles.fade-in, .team.fade-in {
    opacity: 1;
    transform: translateY(0);
}
