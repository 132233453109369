.heroTextHighlight {
    color: #0066FF;
    font-weight: 700;
}


.hero-heading {
    font-size: 3.0rem;
}

.fade-in {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.home-page-hero, .how-it-works, .problem-section, .solution-section, .about-section{
    opacity: 0;
    transform: translateY(20px); /* Start slightly off position */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.home-page-hero.fade-in, .how-it-works.fade-in, .problem-section.fade-in, .solution-section.fade-in, .about-section.fade-in {
    opacity: 1;
    transform: translateY(0);
}
