.fade-in {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.home-page-hero, .how-it-works{
    opacity: 0;
    transform: translateY(20px); /* Start slightly off position */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.home-page-hero.fade-in, .how-it-works.fade-in {
    opacity: 1;
    transform: translateY(0);
}
