.testimonial-container {
    width: 100%;
    overflow: hidden;
}

.testimonial-wrapper {
    display: flex;
    animation: carousel linear infinite;
}

.testimonial-box {
    flex: 0 0 auto;
    width: 400px; /* Adjust width as needed */
    margin-right: 40px; /* Adjust spacing as needed */
}

@keyframes carousel {
     0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
}
