.main-footer {
    background-color: #003554 ;
}

p.footer-text {
    color: white;
}

a.footer-link {
    color: white;
    text-decoration: none;
}

h4.footer-heading {
    color: white;
}